import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax'; 
import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, HeadingWrapper, Title, Line, SubTitle, SolidBackground, TextureBackground, Container, ButtonToggle } from '../components/general/general'
import banner from '../images/index/banner.jpg';
import { palette } from "../styles/variables.js"
import { deviceMax } from "../styles/media"
import { useWindowSize } from "../components/general/window"

// Styled Components

const Background = styled.div`
    position: relative;
    z-index: -1;
    width: 100%;
    height: 40rem;
    background-image: url(${banner});
    background-size: cover;
    margin-bottom: -36.5rem;
 `;
const Overlay = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 15, 41, 0.8);
`;
// const NameWrapper = styled.div`
//     position: relative;
//     width: 100%;
//     margin-bottom: 4rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// `;
// const Title = styled.h1`
//     font-family: "Bebas Neue";
//     font-size: 7rem;
//     color: ${props => props.color};
//     margin: 0;
//     letter-spacing: .2rem;
// `;
// const Line = styled.div`
//     width: 65%;
//     opacity: .8;
// `;
// const SubTitle = styled.h2`
//     font-family: "Raleway";
//     font-size: 3rem;
//     color: ${palette.text90};
//     text-transform: uppercase;
//     text-align: center;
//     max-width: 1072px;
//     white-space: break-spaces;
//     /* margin: 0;
//     letter-spacing: .2rem; */
// `;
const TitleAlt = styled(Title)`
    @media ${deviceMax.laptop} {
        font-size: 6rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 5rem;
    } 
`;
const SubTitleAlt = styled(SubTitle)`
    @media ${deviceMax.laptopL} {
        font-size: 2.5rem;
    }
    @media ${deviceMax.laptop} {
        font-size: 2rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 1.5rem;
    } 
    
`;
// const SolidBackgroundAlt = styled(SolidBackground)`
//   &&& {
//     clip-path: polygon(0 0, 100% 2rem, 100% 100%, 0 100%);
//   }
// `;
// const Container = styled.div`
//     position: relative;
//     width: 1072px;
//     height: 100%;
//     margin-bottom: 6rem;
//     padding-top: 4rem;
// `;
const ContainerAlt = styled(Container)`
    margin-top: 2rem;
`;

const Intro = styled.div`

    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 4rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: pre-line;
    text-align: center;
    background: #1F2239;
    border-radius: 5px;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    font-family: "Raleway";
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.2rem;
    color: ${palette.text90};
    @media ${deviceMax.laptop} {
        font-size: 1.5rem;
    }
    @media ${deviceMax.tablet} {
        flex-direction: column;
    }


`;
const IntroText = styled.div`
    /* width: 60%; */
    margin-right: 1rem;
    @media ${deviceMax.tablet} {
        margin: 0 0 2rem;
    }
`;
const ThermoBox = styled.div`
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    
    /* border: 2px solid red; */
`;
const Thermo = styled.div`
    border: 2px solid ${palette.warmWhite};
    border-radius: 1rem;
    background: #171A2B;
    padding: 1rem;
    margin-bottom: 1rem;
`;
const Distress = styled.h5`
    font-size: 2rem;
    font-weight: 400;
    margin-top: 1rem;
`;
const Subtext = styled.p`
    font-size: 1rem;
    line-height: 1.1;
    font-weight: 400;
`;

const Resources = styled.div`
    position: relative;
    z-index: 5;
    width: 100%;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* white-space: pre; */
    background: #1F2239;
    border-radius: 5px;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.tablet} {
        padding: 2rem 1rem;
    }
    
`;
const Short = styled.div`
    display: block;
    display: ${props => props.visibility? 'none': 'block'};
    transition: .2s;
`;
const Long = styled.div`
    display: block;
    white-space: pre-wrap;
    display: ${props => props.visibility? 'block': 'none'};
    transition: .2s;
`;
const Heading3 = styled.h3`
    margin: 0 0 .5rem ;
    font-family: "Raleway";
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.2rem;
    color: ${palette.text90};
`;
const Heading4 = styled.h4`
    margin: 0 0 .8rem ;
    font-family: "Raleway";
    font-size: 1.5rem;
    font-weight: 600;
    /* padding-bottom: 1rem; */
    color: ${palette.text90};
`;
const StyledText = styled.p`
    font-family: "Alegreya Sans";
    font-size: 1.1rem;
    text-align: left;
    text-justify: inter-word;
    color: ${palette.text90};
    margin-bottom: 3rem;
    /* white-space: pre; */
    /* padding-bottom: 1rem; */

`;
const ListItem = styled.li`
    margin-bottom: 1rem;
`;

const Switch = styled(ButtonToggle)`
  &&& {
    margin-bottom: 2rem;
    border-color: ${props => props.visibility? '#FFF': '#4E7187'};
    background-color: ${props => props.visibility? '#FAA62A': '#0F132E'};
    color: ${props => props.visibility? '#0F132E': '#FFF'};
    width: ${props => props.visibility? '30rem': '20rem'};
    &:hover {
        border-color: #b9d2e2;
    }
    @media ${deviceMax.mobileL} {
        width: ${props => props.visibility? '22rem': '20rem'};
    } 
  }
`;

// const Toggle = () => {

//     const [toggle, setToggle] = useState(false)
//     const handleToggle = () => {
//         setToggle(!toggle)
//     }
// }


const ResourcesPage = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    const [toggle, setToggle] = useState(false)
    const handleToggle = () => {
        setToggle(!toggle)
    }

   



    const shortResourceRichText = props.data.contentfulResources.shortResources
    
    const Bold = ({ children }) => <span>{children}</span>
    // const Text = ({ children }) => <p>{children}</p>

    const optionsShort = {
        // renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
            [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
            [BLOCKS.PARAGRAPH]: (node, children) => <StyledText>{children}</StyledText>,

            [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <>
                <h2>Embedded Asset</h2>
                <pre>
                    <code>{JSON.stringify(node, null, 2)}</code>
                </pre>
                </>
            )
            },
        },
    }

    const longResourceRichText = props.data.contentfulResources.longResources
    
    const optionsLong = {
        // renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
            [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
            [BLOCKS.PARAGRAPH]: (node, children) => <StyledText>{children}</StyledText>,
            [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,

            [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <>
                <h2>Embedded Asset</h2>
                <pre>
                    <code>{JSON.stringify(node, null, 2)}</code>
                </pre>
                </>
            )
            },
        },
    }

    return (
        <Layout>
            <Head title="Need Help?"/>
            <PageContainer>
                <ContentWrapper>
                    <ParallaxProvider>
                        <Parallax
                            y={[-50, 50]}
                            slowerScrollRate
                            disabled={width <= 1024 }
                        >
                            <Background>
                                <Overlay />
                            </Background>
                        </Parallax>
                        <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                        >
                            <HeadingWrapper>
                                <TitleAlt>{props.data.contentfulResources.title}</TitleAlt>
                                <Line>
                                    <StaticImage
                                        src="../images/lines/thick-line.png"
                                        alt="Single Heavy Weight Line"
                                        layout="constrained"
                                        // css={`
                                        //     width: 65%;
                                        //     opacity: .8;
                                        // `}                                       
                                    />
                                </Line>
                                <SubTitleAlt>{props.data.contentfulResources.subtitle}</SubTitleAlt>
                            </HeadingWrapper>
                        </Parallax>
                        <Parallax disabled={width <= 1024 }>
                            <SolidBackground>
                                <TextureBackground>
                                    <ContainerAlt>
                                        <Intro>
                                            <IntroText>{props.data.contentfulResources.introduction.introduction}</IntroText>
                                            <ThermoBox>
                                                <Thermo>
                                                    <StaticImage
                                                        src="../images/thermo/thermo-color-words-center.png"
                                                        alt="Single Heavy Weight Line"
                                                        layout="constrained"                                     
                                                    />
                                                    <Distress>DISTRESS LEVEL</Distress>
                                                    <Subtext>
                                                            What is your current level of distress?
                                                            Use the resources below as needed given the level of your distress. 
                                                    </Subtext>
                                                </Thermo>
                                            </ThermoBox>
                                        </Intro>
                                    
                                        <Resources>
                                        
                                            <Switch onClick={handleToggle} visibility={toggle}>{toggle ? 'CLICK FOR SHORT TERM RESOURCES' : 'CLICK FOR LONG TERM RESOURCES'}</Switch>
                                            <Short visibility={toggle}>{renderRichText(shortResourceRichText, optionsShort)}</Short>
                                            <Long visibility={toggle}>{renderRichText(longResourceRichText, optionsLong)}</Long>
                                        </Resources>
                                    </ContainerAlt>
                                </TextureBackground>
                            </SolidBackground>
                        </Parallax>
                    </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulResources {
            title
            subtitle
            introduction {
                introduction
            }
            shortResources {
                raw
            }
            longResources {
                raw
            }
        }
    }
`
export default ResourcesPage